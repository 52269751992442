<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">活动消耗记录</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="优惠券ID" v-model="pageParam.params.couponId" />
                <le-input label="用户手机号" v-model="pageParam.params.mobile" />
            </le-search-form>
            <le-pagview ref="couponUsePage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.couponUsePagelist">
                <el-table ref="couponUsePagelist" class="couponUsePagelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column label="优惠券ID" prop="couponId" min-width="100"></el-table-column>
                    <el-table-column label="关联订单号" prop="activityTitle" min-width="160">
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="toOrderDetails(row)">{{ row.orderSn?row.orderSn:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="订单类型"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="优惠券金额"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.discountAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="使用时间"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="使用人"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile || '-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.couponUsePage,
                    method: "post",
                    params: {
                        couponId: this.$route.query.id, //
                        mobile: '', //
                    },
                    freshCtrl: 1,
                },
                orderTypeDic: []
            }
        },
        created () {
            this.$getDic('orderType','select').then(res=>{ this.orderTypeDic = res; })
        },
        methods: {
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.bindGoodsId = ''
                this.pageParam.params = {
                    activityRewardsType: '', //活动奖励模式
                    activityTitle: '', //活动名称
                    bindGoodsId: [],// 关联套餐id
                    startTime: '',
                    endTime: '',
                    id: '', //活动id
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['couponUsePage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            toOrderDetails (datas) {
                if(datas.orderType == 1) {
                    this.$router.push({
                        path:'/order/package-order-info',
                        query: {
                            id: datas?datas.orderSn:''
                        }
                    })
                }else if(datas.orderType == 2) {
                    this.$router.push({
                        path: '/order/order-detail',
                        query: {
                            orderId: datas.orderSn,
                        }
                    })
                }else if(datas.orderType == 4 || datas.orderType == 5) {
                    this.$router.push({
                        path:'/order/chargeCabinet-order-info',
                        query: {
                            orderId: datas.orderSn,
                            orderSn: datas.orderSn
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.couponUsePagelist{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
}

/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>